<template lang="pug">
include ../../../../configs/template
form.row.align-end
  div.col-sm-12.col-md-6
    +field-validation('body.additional_info.number_document', 'number', '["required"]')(:readonly="isCheckDocument")
  div(v-if="isCheckDocument").col-sm-12.col-md-6
    +select-validation('body.additional_info.country', 'listCountry', 'country', 'valueLang', '["required"]')
  div(v-if="isCheckDocument").col-sm-12.col-md-6
    +select-validation('body.additional_info.port', 'listPort', 'port', 'nameLang', '["required"]')
  div(v-if="isCheckDocument").col-sm-12.col-md-6
    +field-validation('body.additional_info.captain', 'captain', '["required", "alphaUA"]')
  div.col-sm-12.col-md-6.col-xl-4
    +date-picker-validation('body.additional_info.date_start', 'dateIssue', 'dateStartObject', '["required", "minValue", "maxValue"]', 'isCheckDocument', '!isCheckDocument')(
      min="1900-01-01" :max="new Date().toISOString()"
      :readonly="isCheckDocument")
  div.col-sm-12.col-md-6.col-xl-4
    +date-picker-validation('body.additional_info.date_end', 'dateEnd', 'dateEndObject', '["required", "minValue"]', 'isCheckDocument', '!isCheckDocument')(
      :min="body.additional_info.date_start || '1900-01-01'" max="2200-01-01"
      :readonly="isCheckDocument")
  div(v-if="isCheckDocument").col-sm-12.col-md-6.col-xl-4
    +date-picker-validation('body.additional_info.date_renewal', 'dateRenewal', 'dateRenewalObject', '["required", "minValue"]')(
      :min="body.additional_info.date_end || '1900-01-01'" max="2200-01-01")
  div(v-if="isCheckDocument").col-sm-12
    FileDropZone(ref="mediaContent")
  div.col-12
    v-btn(v-if="isCheckDocument" color="success" @click="saveDocument" :loading="isLoadingSave").mr-4 {{$t('save')}}
    v-btn(v-else color="success" @click="checkDocument" :loading="isLoading") {{$t('btn.checkDocument')}}
    v-btn(v-if="isCheckDocument" color="secondary" @click="clearForm") {{$t('btn.clear')}}
</template>

<script>
import { verificationSC } from '@/mixins/validationRules'
import { mapActions, mapState } from 'vuex'
import { clearBody } from '@/mixins/main'

const initBody = () => (
  {
    additional_info: {
      number_document: null,
      country: null,
      date_start: null,
      date_end: null,
      captain: null,
      date_renewal: null,
      port: null
    }
  }
)
export default {
  name: 'SailorIdentityCard-digitization',
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue')
  },
  data () {
    return {
      body: initBody(),
      isLoading: false,
      isCheckDocument: false
    }
  },
  validations () { return verificationSC.sailorIdentityCard(this) },
  computed: {
    ...mapState({
      lang: state => state.main.lang,
      valueLang: state => state.main.lang === 'en' ? 'value_eng' : 'value',
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      listCountry: state => state.directory.country,
      listPort: state => state.directory.ports,
      isLoadingSave: state => state.documents.isLoading
    }),
    dateStartObject () {
      return this.body.additional_info.date_start ? new Date(this.body.additional_info.date_start) : null
    },
    dateEndObject () {
      return this.body.additional_info.date_end ? new Date(this.body.additional_info.date_end) : null
    },
    dateRenewalObject () {
      return this.body.additional_info.date_renewal ? new Date(this.body.additional_info.date_renewal) : null
    }
  },
  methods: {
    ...mapActions(['getInfoAboutDocumentForDigitizationSC']),
    saveDocument () {
      const { statementId } = this.$route.params
      if (this.$v.$invalid) return this.$v.$touch()
      let data = {
        body: { ...this.body },
        media: {
          name: 'SeafarerPassDoc',
          files: [...this.$refs.mediaContent.filesArray]
        }
      }
      this.$emit('saveDocument', { id: statementId, data, method: 'setDigitizationDocumentSC' })
    },
    async checkDocument () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.isLoading = true
      const { statementId } = this.$route.params
      const response = await this.getInfoAboutDocumentForDigitizationSC({ id: statementId, body: { additional_info: { ...clearBody(this.body.additional_info) } } })
      if (response) {
        this.$notification.success('notify.success.checkDocument')
        this.isCheckDocument = true
      }
      this.isLoading = false
    },
    clearForm () {
      this.isCheckDocument = false
      this.body = initBody()
      this.$v.$reset()
    }
  }
}
</script>
